export default {
  header: {
    vi: 'Vietnamese',
    en: 'English',
    solution: 'Solutions',
    tech_gold: 'NFC Technology Gold Coins',
    mobile_app: 'HanaGold Application',
    happy_stone: 'Happy Stone Jewelry',
    gift: 'Gifts for Bussiess Partners',
    picture: 'Gold-plated Paintings',
    statue: 'Feng Shui Statues',
    about: 'About Us',
    intro: 'Introducing HanaGold',
    partner_a: 'Business Partners',
    cer: 'License - Certificate',
    brand: 'HNG Branding',
    partner_b: 'Partners',
    affiliate: 'HanaGold Ambassadors',
    franchise: 'E-Franchise',
    market: 'Market',
    gold_price: 'Gold Prices',
    rate: 'Exchange Rate',
    news: 'News',
    gold_market: 'Gold Market',
    sale: 'Promotion',
    mess: 'Newsletter',
    noti: 'Announcements',
    knowledge: 'Investment Knowledge',
    ambassador: 'HanaGold Ambassadors',
    support: 'Support Center',
    instructions: 'User Guides',
    tele: 'Contact Customer Service',
    thi_truong_vang: 'Gold Market',
    chuong_trinh_uu_dai: 'Promotion',
    ban_tin: 'Newsletter',
    thong_bao: 'Announcements',
    kien_thuc_dau_tu: 'Investment Knowledge',
    dai_su: 'HanaGold Ambassadors',
    gia_vang: 'Gold Prices',
    ty_gia_ngoai_te: 'Exchange Rate',
    huong_dan_su_dung: 'User Guides',
    technology_gold: 'NFC Technology Gold Coins',
    trang_suc_happy_stone: 'Happy Stone Jewelry',
    tranh_ma_vang: 'Gold-plated  Paintings',
    tuong_phong_thuy: 'Feng Shui Statues',
    gold_tap_tap: 'Gold Tap Tap',
    project: 'Project',
    gold_atm: 'Gold ATM',

    sign: 'Register',
    log: 'Login',

    out: 'LOG OUT',
    mana: 'Gold Management',
    setting: 'General Setting',
    se: 'Security',
    text_m_h: 'Download the HanaGold app for optimal experience',
  },

  footer: {
    intro: 'Introducing HanaGold',
    store: 'Store Chains',
    product: 'Products',
    news: 'News',
    introduce: 'Instruction',
    introduce_a: 'Instruction on how to buy',
    introduce_b: '	Instruction on how to pay',
    introduce_c: 'Instruction on how to preserve jewelry',
    introduce_d: 'Instruction on how to measure jewelry size',
    introduce_e: 'How to deliver and receive goods',
    policy: 'TERMS AND POLICIES',
    policy_a: 'Check Gold Coins Content',
    policy_b: 'Announce the standard basis',
    policy_c: 'Warranty Regulations',
    policy_d: 'Privacy Policy',
    policy_e: 'Terms of Service',
    contact: 'CONTACT',
    name: 'HanaGold Jewelry and Gemstones Joint Stock Company',
    add:
      'Lot SI.26 - Ground Floor - Block Mercury - Q7 Saigon Riverside Complex - 04 Dao Tri Street, Phu Thuan Ward, District 7, Ho Chi Minh City, Vietnam',
    tax:
      '0316531254 • Ho Chi Minh Department of Planning and Investment issued on 09/10/2020',
    touch: 'CONNECT WITH US',
    community: 'HANAGOLD COMMUNITY',
    community_a: 'Stay updated with the latest information',
    join: '	Join now',
  },

  home: {
    h_app: 'HanaGold Application',
    h_app_d: 'Accumulate Gold Together',
    video: 'Explore more about HanaGold',
    partner: 'Gold Price Conversion',
    trans: 'Gold Price Conversion',
    trans_a: 'Amount of Gold to Buy',
    trans_b: 'MACE',
    trans_c: 'Payment Amount',
    trans_d: 'VND',
    trans_e: 'See more gold prices',

    so: 'Business Solutions',
    so_a: `HanaGold Jewelry Gemstone Joint Stock Company was established in 2020, a pioneer in innovation, applying 4.0 technology in the field of gold, silver and gemstones. <br /> <br /> HanaGold has succeeded in bringing the experience of buying gold without queuing from only 100,000 VND. Accumulate safely, conveniently and quickly anywhere or anytime on the HanaGold app.`,

    so_b: `HanaGold Jewelry Gemstone Joint Stock Company was established in 2020, a pioneer in innovation, applying <br /> 4.0 technology in the field of gold, silver and gemstones. HanaGold has succeeded in bringing the experience of buying <br /> gold without queuing from only 100,000 VND. Accumulate safely, conveniently and quickly anywhere or anytime on the HanaGold app.`,

    so_t_a: 'HanaGold Application',
    so_d_a: `Gold accumulation solution from <span>100,000 VND</span>. Customers can exchange for physical gold coins Kim Peacock 99.99 when accumulating at least 01 MACE of gold`,

    so_t_b: 'Technology Gold Coins',
    so_d_b: ` <span>Kim Khong Tuoc 99.99 Gold Coin</span> integrates
    NFC technology with asset identification capability, preventing
    counterfeit and low-quality gold floating in the market.
      </p>`,

    so_t_c: 'HanaGold Ambassador',
    so_d_c: `Individuals who want to <span>increase their income</span> with commission policy when sharing the values of products and services of HanaGold to the community.,`,

    so_t_d: 'HanaGold Ambassador',
    so_d_d: `Business model of franchising HanaGold brand on the online platform with low capital from <span>30,000,000 VND</span>.`,

    achie: 'HanaGold’s Achievements',
    achie_des:
      'Over more than 3 years of development, we have achieved proud milestones, which are the motivation for the HanaGold team to continuously improve customer experience.',

    achie_a: 'Customers trust the HanaGold application',
    achie_b:
      'Investment capital from FUNDGO Investment Fund, Heralding Capital, and Go Global Franchise Fund',
    achie_c: 'Number of downloads of HanaGold app on App Store/Google Play',
    achie_d: 'Branches include Ho Chi Minh City, Hanoi, Da Nang, Can Tho,… ',
    achie_e: 'Partners, associations, organizations accompanying development',
    achie_f: 'HanaGold Ambassadors journey to prosperity with business',

    about: 'What Does the Press Say About HanaGold?',

    se: 'Safety and Security',
    se_h_a: 'Payment Assurance',
    se_h_b: 'Multi-layer Security',
    se_h_c: 'System Infrastructure',

    se_a: `Linked with 9 banks and payment gateways MoMo, EPay.`,
    se_b: `Deposit/withdraw money from bank to app or from app <br /> to linked bank anytime, anywhere.`,
    se_c: `Customer information is collected for the purpose of providing <br /> services and managing the database of Consumers and timely <br /> handling arising situations (if any).`,
    se_d: `Double security mechanism including payment password, PIN verification <br /> and OTP, applying biometric security technology in service provision <br /> such as fingerprint authentication, FaceID`,
    se_e: `System to identify and detect abnormal transactions.`,
    se_f: `Customer service system 24/7 to meet all needs <br /> of inquiries, complaints, support… in any case.`,
    se_g: `All your information is secured by the system infrastructure <br /> of Third Party Partners which are AWS and Google Cloud.`,
    se_h: `The personal information of Users is committed by the Management Board to be absolutely confidential <br /> according to the personal information security policy posted on <br /> the Website <a href=“https://hanagold.vn”> https://hanagold.vn </a> `,
    system: 'Store Chains',
    read_more: 'See more',
    sign_now: 'Register for freeqq',
    news_hng: 'News HanaGold',
  },

  tech_gold: {
    slider_so: 'Gold Coin Identification Solution Kim Khong Tuoc 24K',
    slider_title: `  <h2 class="kfsj2jad sub-title"> 24K Khong Tuoc Gold Coin, Integrated with NFC Technology</h2>`,
    buy_now: 'Buy Now',
    first: 'Pioneer in Digital Transformation',
    gold_a: 'Gold Business Industry HanaGold',
    gold_a_des:
      'HanaGold has successfully researched and developed Peacock 24k gold coins integrated',
    gold_b_des:
      'with technology with the ability to identify assets, trace origins and prevent fake gold floating on the market.',
    quality: '*Certificate of standard announcement',
    meaning: `<h2 class='sub-title'>Meaning</h2> <p>Technology Gold Coin was HanaGold’s idea <br /> designed with the symbol of the peacock representing <br /> <span>Happiness - Nobility - Luck - Peace</span> <br /> for customers who own it. </p>,`,
    partner: `<h2 class='sub-title'>Strategic Partners</h2> <p> <span style={{ fontStyle: ‘normal’ }}> The jewelry experts of VIETAGOLD </span>  have used the most modern production technology to turn the <br />ideas into a beautiful 24k gold coin product, <br /> refined with a blend of traditional beauty and <br /> technology that ensures the transmission of meaning, expectations that customers desire. </p>`,
    embed_tech: 'Integrated with Technology System',
    gold_phoneix_t: 'Khong Tuoc Gold Coin',
    gold_phoneix_des_a: ' Weight: <span>1 MACE</span>',
    gold_phoneix_des_b: ' Mass: <span>3.75grs</span>',
    gold_phoneix_des_c: ' Quality: <span>24K</span>',
    gold_phoneix_des_d: ' Size: <span>20mm</span>',
    benefit: 'Ownership Benefits',
    benefit_a: `<p>   Helps identify the owner, legitimate gold assets <br /> and   protects assets in case of disputes. </p>`,

    benefit_b: `<p>Aids in tracing the origin of the product, gold quality, <br /> detailed product information, transparency, <br /> and transaction history tracking. </p>,`,

    benefit_c: `<p>Prevents counterfeit gold, poor-quality gold, andbrand impersonation. </p>",`,

    benefit_d: `<p> Certifies gold using unique NFT technology <br /> exclusive to a single owner, transferable as a gift <br /> through the HanaGold app. </p>,`,

    benefit_e: `<p>    Helps gold shops manage inventory, protect <br /> their customers, and avoid buying counterfeit or <br /> unclear origin gold.  </p>,`,

    benefit_mb_a:
      'Helps identify the owner, legitimate gold assets, and protects assets in case of disputes',
    benefit_mb_b:
      'Aids in tracing the origin of the product, gold quality, detailed product information, transparency, and transaction history tracking',
    benefit_mb_c:
      'Prevents counterfeit gold, poor-quality gold, and brand impersonation.',
    benefit_mb_d:
      'Certifies gold using unique NFT technology exclusive to a single owner, transferable as a gift through the HanaGold app',
    benefit_mb_e:
      'Helps gold shops manage inventory, protect their customers, and avoid buying counterfeit or unclear origin gold.',

    faq: 'Frequently Asked Questions',
    faq_sub: 'For HanaGold Customers',
    list: 'FAQ Categories',

    products: 'Products',
    tech: 'Technology',
    policy: 'Policies',
    guide: 'Guides',

    products_a: `What is HanaGold's technology gold coin?`,
    products_b:
      'Which strategic partners are involved in the production of the product?',
    products_c: `What are the differences between HanaGold's technology gold coin and traditional gold coins?`,
    products_d:
      'Does the technology gold coin have a certification for customer ownership?',
    products_e: 'How is the gold coin standard assessment conducted?',

    products_des_a: `
    <p>      HanaGold's 24K technology gold coin, named      Khong Tuoc, integrates with NFC technology to identify      and trace the origin and source of the gold.      <a        href="https://hanagold.vn/news/hanagold-cong-bo-giai-phap-dinh-danh-dong-vang-kim-khong-tuoc"        target="_blank"      >        Learn more      </a>    </p>    <h6>Gold coin information:</h6>    <p>      Material: 24K (999.9) <br /> Purity:      99.99%      <br /> Weight: 1 MACE - 3.75 grams <br />      Size: 20mm <br /> Chip: NFC    </p>
`,

    products_des_b: `
   <p>HanaGold's technology gold coin is manufactured byVIETAGOLD, a leading company in gold, silver, and gemstones. <a  href="https://hanagold.vn/news/hanagold-cong-bo-giai-phap-dinh-danh-dong-vang-kim-khong-tuoc"  target="_blank"> Learn more</a></p>",
`,

    products_des_c: `
      <p>  Integrated with NFC technology on each gold coin,  ensuring safety for owners when accumulating at  HanaGold</p>
`,

    products_des_d: `
      <p>  Yes. Each activated gold coin comes with a PDF-format identification certificate sent to the customer's email by HanaGold.</p>,
`,
    products_des_e: `<p>  HanaGold's 24K technology gold coin has officially announced the applied standard according to TCCS 01/2023/HNG and test results from the Mechanical Testing Department - Building Materials.  <a    href="https://hanagold.vn/news/hanagold-cong-bo-tieu-chuan-dong-vang"    target="_blank">    Learn more  </a></p>`,

    tech_a: 'What technology system is integrated and used?',
    tech_b: 'What is NFC technology?',
    tech_c: 'How is NFC technology used to identify gold assets?',
    tech_d: 'What is Fintech technology and how does it operate?',
    tech_e: 'What is Blockchain technology?',
    tech_f: 'What is NFT technology?',

    tech_des_a: `
    <p>With the remarkable development of 4.0 technology in Vietnam and the world in recent years, HanaGold has successfully accessed and applied Fintech (financial technology), NFC, and Blockchain (NFT certificate) technologies to the Kim Khong Tuoc HanaGold gold coin product. 
    <a href="https://hanagold.vn/news/cong-nghe-tich-hop-trong-san-pham-cua-hanagold" target="_blank">Learn more</a></p>
   `,

    tech_des_b: `<p>
    NFC stands for Near-Field Communications, meaning close-range communication. It is a wireless technology that enables the transmission of information and data between devices at close distances (from a few centimeters to about 10cm) through radio waves. This technology uses magnetic field sensing to establish connections between devices when in direct contact or close proximity.
  </p>`,
    tech_des_c: ` <p>
    - Each 24k Kim Khổng Tước gold coin is integrated with an NFC chip and linked through the HanaGold app.
<br /><br />- NFC allows access to product origin, gold quality, detailed product information, quick and accurate transaction history queries.
<br /><br />- When data access is needed, customers only need to scan the gold coin on their phone through the HanaGold app to check the gold coin information.
  </p>`,

    tech_des_d: `<p>
    Fintech is short for Financial Technology, a combination of technology and finance. Fintech involves applying new technologies such as artificial intelligence (AI), machine learning (ML), blockchain, big data, etc., to improve and enhance the quality of traditional financial services. 
<br /><br />Fintech has had a significant impact on the financial industry, making financial services more accessible, cost-effective, and efficient.
  </p>`,

    tech_des_e: `<p> Blockchain technology is a distributed, decentralized database technology capable of storing information securely and transparently. Data in Blockchain is stored in blocks (Block), which are linked together in a chain (Chain). Each block contains information about the previous block, including the creation time, the data stored, and the hash of the previous block. </p>`,

    tech_des_f: `<p> NFT (Non-Fungible Token) is a unique digital asset stored on blockchain. NFT can represent anything digital such as images, videos, music, games, and items in the real world. <br /> <br /> NFT is created by encrypting a digital file with a smart contract (Smart contract). The smart contract records the ownership of the NFT and ensures that it can only be owned by one person at a time. <a href="https://hanagold.vn/news/giay-chung-nhan-vang-nft" target="_blank"  > Learn more </a> </p>`,

    policy_a: `How does HanaGold's gold purchase policy work?`,
    policy_b: 'What happens if the product I purchased is defective?',

    policy_des_a: `<p> - Customers need to bring the product to one of 4 branches of HanaGold nationwide to be appraised according to regulations. In case of correct product of HanaGold, it will be bought back at the price of raw gold. In case the product is not of HanaGold, HanaGold will send the appraisal results to customers and will have to pay the inspection fee according to HanaGold’s Fee Schedule for each specific time point. <br />
    <br /> Step 1: HanaGold will check and trace the source of the product using NFC technology on the application HanaGold <br />
    <br /> Step 2: Appraise the gold coin, product packaging <br />
    <br /> Step 3: HanaGold reports the status and value of the product recovery. Both parties confirm the transaction <br />
    <br /> Step 4: HanaGold disburses the money to buy gold </p>`,

    policy_des_b: `<p> - Products that are defective due to the manufacturer will be warranted exchange according to each batch; <br /> <br />- In case the product is defective, damaged due to the storage, preservation, use of customers, HanaGold will charge a fee when buying back the product. </p>`,

    guide_a: 'Where and who can I buy and sell technology gold coins from?',
    guide_b: 'How to scan technology gold coins on the HanaGold app',
    guide_c: 'How do I transfer the ownership of the gold coin?',
    guide_d: 'How do I report that the gold coin has been lost?',

    guide_des_a: `<p> Customers can buy and sell directly 24k technology gold coins of HanaGold at 04 stores nationwide, including <span>Ho Chi Minh City, Hanoi, Da Nang, Can Tho</span>. Customers just need to order on the HanaGold app, the product will be delivered to the customer’s hands. Or contact directly via Hotline 19002161, Zalo OA{ <a href=" https://zalo.me/vanghanagold" target="_blank" > https://zalo.me/vanghanagold </a> for HanaGold to directly consult and guide the order details. </p>`,

    guide_des_b: `
    <p>
    First, the customer's mobile device needs to have NFC functionality.
    <br /><br />Step 1: On the main screen, select 'Categories.'
    <br /><br />Step 2: Scroll down, select 'Gold coin identification.'
    <br /><br />Step 3: Select 'Scan NFC.'
    <br /><br />Step 4: Place the gold coin on the back of the phone for 3s - 5s.
    <br /><br />Note: The scanning position may vary for each phone, so it's advisable to try placing it in different positions on the back of the phone to ensure successful NFC activation.
    </p>
 `,
    guide_des_c: `<p>
    Step 1: On the gold coin details screen, select 'Transfer ownership.'
<br /><br />Step 2: Enter the recipient's Email/Phone number and select 'Transfer ownership.'
<br /><br />Step 3: A confirmation popup appears, click Confirm.
<br /><br />Note: Email/Phone number must be in the HanaGold system, and the customer must verify the account.
  </p>`,

    guide_des_d: `
    <p>
    <p>Step 1: On the gold coin details screen, select 'Report lock.'
    <br /><br />Step 2: A confirmation popup appears, click Confirm to complete the process of locking this gold coin transaction. The Transfer ownership feature will not work unless the customer cancels the request.
    <br /><br />Note: Within 24 hours of canceling the request, this feature will be locked for this gold coin.</p>
  </p>`,

    community: '4.0 Gold Accumulation Community',
    community_des: 'For HanaGold Customers',
    community_btn: 'Join now',
  },

  mobile_app: {
    title: 'HanaGold Application',
    title_b: 'Accumulate Gold Together',
    title_c: `Start investing and managing your gold assets <br /> with prices starting from 100,000 VND`,
    btn_a: 'Discover now',

    intro: 'Introduction',
    intro_des: `The HanaGold app helps customers buy, sell, accumulate and manage gold assets easily, safely and effectively
    <br /> <br /> Customers can accumulate gold sustainably with a small capital from 100,000 VND, along with many features that generate profits every day and withdraw money quickly and conveniently.
    <br /> <br /> Especially, customers can also own HanaGold technology gold coins integrated with NFC to prevent fake gold, trace the origin of products and identify customers who own them`,

    benefit: 'Accumulation Benefits',

    benefit_t_a: 'Buy gold conveniently 24/7',
    benefit_d_a: `At the HanaGold app, you can buy, sell gold <br /> and pay online 24/7 conveniently, quickly, <br /> easily, ensuring to avoid the common risks <br /> when buying traditional gold`,

    benefit_t_b: 'Accumulate gold with small capital',
    benefit_d_b: `Customers can easily accumulate gold with small capital only from 100,000 VND, increasing the opportunity to own gold for low-income people`,

    benefit_t_c: 'Low buy-sell spread',
    benefit_d_c: `HanaGold’s gold price can now be affirmed <br /> very competitive with other big brands, <br /> not only that the buy-sell spread is only 50,000 VND / tael while other brands fall into 80,000 VND -120,000 VND / tael, so customers of HanaGold have the opportunity to close higher profits and profit more when trading`,

    benefit_t_d: 'Quick origin tracing',
    benefit_d_d: `HanaGold’s gold products are integrated with <br /> NFC technology to help customers easily check information, <br /> gold quality conveniently Online invoice documents: <br /> When buying gold at HanaGold, the invoice will be saved <br /> online, accessed at any time, ensuring high <br /> security and confidentiality`,

    benefit_t_e: 'Diverse Profitable Investment Portfolio',
    benefit_d_e: `Accumulating gold is easy with investment capital. With features such as "Daily Rewards" and </br> "Co-investing" with different investment periods such as 30, 90, and 365 days, providing sustainable profit opportunities for customers.`,

    benefit_t_f: 'Automatic Savings',
    benefit_d_f: `The HanaGold app features "Raise Golden Pigs" <br />to help customers plan their gold accumulation <br /> daily, weekly, or monthly without worrying too much <br />about price fluctuations,proactively manage assets <br /> and help achieve financial goals steadily`,

    benefit_t_g: 'Market news and transparent gold price',
    benefit_d_g: `HanaGold always provides information on fluctuations in gold prices, gold market news in Vietnam and the world to help customers always update in time, besides HanaGold’s gold price is always public transparent 24/7 to help customers make accurate buying and selling decisions`,

    feature: 'Unique Features',

    feature_t_a: 'Buy Gold',
    feature_d_a: 'Accumulate gold quickly with one action, from 100,000 VND',

    feature_t_b: 'Co-invest',
    feature_d_b:
      'Earn daily rewards with investment terms of 30, 90, and 365 days.',

    feature_t_c: 'Raise Golden Pigs',
    feature_d_c: 'Automatically accumulate gold suitable for busy individuals.',

    feature_t_d: 'E-Voucher',
    feature_d_d: 'Gift gold to relatives, friends, and family.',

    feature_t_e: 'Refer Friends',
    feature_d_e:
      'Increase income by 500,000 VND in the referral wallet when introducing friends to use HanaGold.',

    feature_t_f: 'Daily Rewards',
    feature_d_f:
      'Receive daily rewards when holding 0.5 gold Mace in the main wallet.',

    tech: 'HanaGold Integrates Blockchain Technology',
    tech_a: 'Blockchain Technology from OnusChain',

    save: 'HanaGold - Investment and Accumulation',
    save_a: 'Gold NFC Technology',

    feeling: 'Customer Feedback',
    feeling_a: 'About HanaGold Experience',

    about: 'About HanaGold',
    about_text: `HanaGold Precious Metals Corporation was established in 2020, an innovative startup in the field of precious metals. We apply 4.0 technology in the operation of gold business (4.0 technology gold shop). <br /> <br /> The potential in the era of 4.0 technology along with the demand for use and investment in the gold market, HanaGold quickly seized the opportunity to develop the gold industry in Vietnam according to a more improved model than traditional, creating a breakthrough for the gold business in Vietnam. <br /> <br /> Our mission is to ensure the value of your assets and bring peace of mind when bonding.`,

    btn_res: 'Sign Up',
  },

  partners: {
    title: 'HANAGOLD PARTNERS',
  },

  certificate: {
    title: 'Licenses - Certificates',

    cer_a: 'Certificate of HanaGold software',

    cer_b: 'Certificate of Kim Khong Tuoc Jewelry',

    cer_c: 'Certificate of Kim Khong Tuoc',

    cer_d: 'Certificate of Third Prize TECHFEST 2021',

    cer_e: 'Trademark Registration',
    association: 'Members - Association',

    association_a: 'Young Entrepreneurs Association',
    association_b: 'Vietnam Association of Gold, Silver and Gemstone Arts',
    association_c: 'Honorary Member of the Vietnam Blockchain Association',
    association_d:
      'Ho Chi Minh City Association of Gold, Silver and Gemstone Arts',
    association_e: 'Franchise Club',
    quality: 'Gold Standard',
    quality_a: 'Announcement of the Gold Standard Facility',
    quality_b: 'Gold Testing',
  },

  aff: {
    title: 'HanaGold Ambassador',
    title_a: 'Prosper with HanaGold',
    title_b:
      'Opportunity to increase financial income and personal development, build a professional business team',
    btn_a: 'Register now',

    policy_a: 'Commission Policy',
    policy_a_des:
      'Unlimited Commission - Convert potential customers on social media',
    more: 'Learn more',

    policy_b: 'About the HanaGold app',
    policy_b_des:
      'Start increasing income with the outstanding features of the HanaGold app',

    sign_a: 'Participate in in-depth training and development programs',
    sign_b: 'Receive various types of commissions, special offers',
    sign_c:
      'Get priority support when encountering problems while using HanaGold',
    sign_d: 'Participate in promotional and marketing activities',

    schedule: 'Development Roadmap',

    schedule_a: `<h3 class="t_d">Shareholder</h3> <h3 class="t_mb">Shareholder</h3> <p> Desire to own shares and participate in the development with the Board of Directors and the Board of Management for over 03 years. </p>`,

    schedule_b: `<h3 class="t_d"> Franchise <br /> Store </h3> <h3 class="t_mb">Franchise Store</h3> <p> A business model of franchising gold stores under the brand name HanaGold at geographical locations. </p>`,

    schedule_c: `<h3 class="t_d"> Franchise <br /> Online </h3> <h3 class="t_mb">Franchise Online</h3> <p> A business model of franchising the HanaGold brand on an online platform. </p>`,

    schedule_d: `<h3 class="t_d"> HanaGold<br /> Ambassador </h3> <h3 class="t_mb">HanaGold Ambassador</h3> <p> Individuals who want to increase their income with the commission policy when sharing the product values, services of HanaGold to the community. </p>`,

    schedule_e: `<h3 class="t_d">User</h3> <h3 class="t_mb">User</h3> <p> Customers who use the products, services of HanaGold. </p>`,

    step: 'Steps to Join',

    step_a: `<h3>Registration</h3> <p> Register directly on the <br /> HanaGold app </p>`,

    step_b: `<h3>Verification</h3> <p>HanaGold reviews the registration profile</p>`,

    step_c: `<h3>Training</h3> <p>Expertise & skills</p>`,

    step_d: `<h3>Upgrade</h3><p>Become a HanaGold Ambassador</p>`,

    share: 'Share the Journey',
    share_a: 'Prosper with',
    share_b: 'HanaGold Ambassador',

    share_text_a: `HanaGold has transformed my business since I discovered it. It allows me to accumulate and trade gold with ease. The best feature is the NFC technology that authenticates the gold coin Kim Khong Tuc. It gives me peace of mind and protects me from counterfeits. Moreover, HanaGold boosts my economic growth through its generous referral program. I can earn attractive commissions by sharing the value of its products and services with others. I hope HanaGold will continue to thrive and innovate.`,
    share_text_b: `A niche market without a clear direction. The fund segment creates community value. Charity. Fundraising from now and operate in parallel. The fund goes where it operates. It will bring the full meaning and value. <br /> <br /> HanaGold is a pioneer. Investors respond. Operate persistently. The breakthrough potential as I think will be stronger.`,

    share_text_c: `When becoming an HanaGold Ambassador, I learned more a lot of knowledge about personal financial management as well as safe gold accumulation. In addition, this is also an opportunity to increase passive income without limit when introduce friends to accumulate. The Ambassador policy of HanaGold is a tool for everyone to build their own financial freedom path.`,

    share_text_p: 'Development Ambassador',

    about_title: 'About HanaGold',
    about_text: `HanaGold Precious Metals Corporation was established in 2020, an innovative startup in the field of precious metals. We apply 4.0 technology in the operation of gold business (4.0 technology gold shop). <br /> <br /> The potential in the era of 4.0 technology along with the demand for use and investment in the gold market, HanaGold quickly seized the opportunity to develop the gold industry in Vietnam according to a more improved model than traditional, creating a breakthrough for the gold business in Vietnam. <br /> <br /> Our mission is to ensure the value of your assets and bring peace of mind when bonding.`,

    sign: 'Register to become',
    sign_aff: 'HanaGold Ambassador',
    app: 'HanaGold Application',
    app_text: 'Accumulate Gold Together',
    btn_b: 'Register now',
    btn_sign: 'Register',
  },

  franchise: {
    fr_l_a: `Bringing golden business opportunities extremely easy, simple, and convenient with HanaGold.`,

    fr_l_b: `Providing entrepreneurial opportunities in the gold business on HanaGold's existing platform.`,

    fr_l_c: `Together with HanaGold, create a chain of franchised gold stores online across Vietnam and internationally.`,

    copy_text: 'Coppy',

    title: 'E-Franchise',
    title_text: `The model of franchising the HanaGold brand on <br /> the online platform. Investors can easily own a store <br /> selling gold jewelry with a small capital, flexible in time and <br /> location of business`,

    be: `Cooperation Benefits <span>Business</span>`,

    be_a: `<h3>Selling gold jewelry 24/7</h3> <p> On the online platform without having to invest <br /> too much cost for the store. </p>`,
    be_b: `<h3>Small capital</h3> <p> Optimize franchising and operating costs with <br /> capital from 30,000,000 VND. </p>`,
    be_c: `<h3>HanaGold Platform</h3> <p> Entitled to use the platform and brand <br /> HanaGold to do business with the highest <br /> commission policy. </p>`,
    be_d: `<h3>Break-even time</h3> <p> Quickly and high income when Partners build <br /> and successfully develop a loyal customer <br /> file. </p>`,
    be_e: `<h3>Partner information</h3> <p> Announced and displayed on the website <br /> <a> hanagold.vn</a> and guaranteed by the <br /> HanaGold brand. </p>`,
    be_f: `<h3>Commission policy</h3> <p> With the highest level and many features that bring <br /> opportunities to increase passive income. </p>`,

    title_se: `franchise category <span>rights</span>`,

    table_a_title_a: `No`,
    table_a_title_b: `Category`,
    table_a_title_c: 'Content',

    rank_a: 'Investment Capital',
    rank_b: 'Online Franchise Account',
    rank_c: 'Online Franchise Contact',
    rank_d: 'Online Franchise Partner Certificate',
    rank_e: 'Technology Platform, Business Documents',
    rank_f: 'Combo Support Package (images, communication, training...)',
    rank_g: '*HanaGold gives Partners',

    title_sp: `support package <span>300,000,000 VND</span>`,

    table_b_title_b: 'Content',
    table_b_title_c: `Value`,

    sp_a: 'VIP HanaGold Account',
    sp_b: 'Business Skills Training Package',
    sp_c: 'Consulting and media promotion',
    sp_d: 'Marketing Tools',
    sp_e: 'HanaGold Technology Platform',
    sp_f: 'The right to use the HanaGold trademark',

    title_ex: `Financial <span>Illustration</span> Table`,

    ex_a: 'Customer Development Revenue',
    ex_b: 'Gold Distribution Commission (10 Mace/Customer)',
    ex_c: 'Upgrade to VIP HanaGold Account',
    ex_d: 'Contract with cooperation (10 Mace - 10,000 HNG)',
    ex_e: `<span className="text_white_b_i">(*)</span> Monthly Sales Bonus`,
    ex_f: 'Total Income',

    text_des: `(*) Monthly Sales Bonus: <span> Total sales when granting Business Ambassadors and User withdrawals from the commission wallet </span>`,

    sp_title: 'Advisory Board',

    sp_des_a: `International Franchise Expert`,
    sp_des_b: `CEO <br /> Go Global Franchise Fund`,
    read_more: 'See more',

    fr_step_title: 'Journey to become',
    fr_step_title_a: 'Online Franchise Partners',

    fr_step_title_center: 'REGISTRATION PROCESS',
    fr_step_a: 'Register',
    fr_step_a_des: `After thorough research and consultation <br /> about E-Franchise, partners proceed <br /> to register with HanaGold`,
    fr_step_b: 'Assessment',
    fr_step_b_des: `This is the step to support partners to determine the level <br /> suitable with the E-Franchise business model <br /> and have enough capacity to cooperate”, “Signing And Announcing Partners`,
    fr_step_c: 'Signing And Announcing Partners',

    fr_step_c_des: `Sign a cooperation contract and announce the news <br /> officially become an Online Franchise partner of HanaGold`,
    fr_step_d: 'Training & Business Deployment',
    fr_step_d_des: `Partners participate in training sessions <br /> product knowledge and skills, and at the same time <br /> hand over business tools`,
    faq: 'Frequently Asked Questions',
    faq_list: 'FAQ Categories',

    faq_list_a: 'Model',
    faq_list_b: 'Products',
    btn_a: 'Policies',

    /* 
    
    dấdassa

    */

    faq_list_a_title_a: 'What is E-Franchise?',
    faq_list_a_title_des_a:
      'The model of franchising the HanaGold brand on the online platform. The model helps investors to easily own a gold jewelry store with a small capital, flexible in time and location of business.',

    faq_list_a_title_b: 'What is the core value of E-Franchise?',
    faq_list_a_title_des_b: `- Provide the opportunity to do gold business very easily simple, convenient with HanaGold <br /> <br /> - Give the opportunity to start a gold business on the available platform of HanaGold <br /> <br />- Together with HanaGold, create a chain of online gold franchise stores across Vietnam and International`,

    faq_list_a_title_c: 'Which business model is suitable for whom?',
    faq_list_a_title_des_c: `-  Want to do business but don’t know where to start and limited capital, management skills… <br /> <br />- Want to have more income through the form of online business <br /> <br />- Those who already have a business gold, jewelry… and want to expand the business model online <br /> <br />- Those who like to be self-employed business, flexible time, not dependent fixed location`,

    faq_list_a_title_d: 'Why should I join the E-Franchise cooperation?',
    faq_list_a_title_des_d: `- Start a gold business with a very small capital only 30,000,000 VND <br /> <br />- Optimize operating costs, simple management with the Online Franchise business model with HanaGold <br /> <br />- Very attractive commission policy for partners <br /> <br />- Develop personal brand with HanaGold <br /> <br />- Supported from A- Z`,
    faq_list_a_title_e: 'What are the benefits of joining?',
    faq_list_a_title_des_e: `- Selling gold jewelry 24/7 on the online platform without having to invest too much cost for the store <br /> <br /> - Using the platform, HanaGold brand to do business with the highest commission policy <br /> <br />- Exclusive support for quickly resolving issues related to customers, dedicated support staff <br /> <br />- Recover capital quickly and high income when Online Franchise builds and develops successfully loyal customer file <br /> <br /> - Information of E-Franchise partner will be announced and displayed on HanaGold website ensuring the guarantee from the brand <br /> <br />- Supported training and communication throughout the business process with HanaGold.`,
    faq_list_a_title_f: 'What does the franchise package include?',
    faq_list_a_title_des_f: `- Investment capital <br /> <br /> - Online Franchise Account HanaGold <br /> <br />- Online Franchise Contract <br /> <br />- Online Franchise Partner Certificate <br /> <br /> - Technology platform, business documents <br /> <br />- Combo support package (images, communication, training…)`,
    faq_list_a_title_g: 'What is the commission policy like?',
    faq_list_a_title_des_g: `See more <a href="https://drive.google.com/file/d/1OfPFfUR1wesk3I37vSs3KMnkg3NTxBWs/view" target="_blank" > here </a>`,
    faq_list_a_title_h: 'How to become the E-Franchise?',
    faq_list_a_title_des_h: `<h6>Step 1: REGISTER</h6> <p> - After learning and being consulted carefully about E- Franchise, partners proceed to register with HanaGold </p> <h6>Step 2: APPRAISAL</h6> <p> - This is the step to support partners to determine the level suitable with the E- Franchise business model and have enough capacity to cooperate </p> <h6>Step 3: SIGNING</h6> <p> - Sign a cooperation contract and announce the news officially become an E- Franchise partner of HanaGold </p> <h6>Step 4: TRAINING</h6> <p> - Partners participate in training sessions product knowledge and skills, and at the same time hand over business tools </p>`,
    /* 
      dấdassa
    */
    faq_list_b_title_a: 'What is the product of business?',
    faq_list_b_title_des_a: `<p>HanaGold App - Accumulate Gold 4.0</p>`,

    faq_list_b_title_b: 'What features do I need to use to do business?',
    faq_list_b_title_des_b: `<h6>Partners will do business based on the features: </h6> <p> - Introduce friends <br /> - Buy gold <br /> - Raise gold pigs <br /> - Cooperate together <br />- Upgrade VIP <br />- Commission wallet </p>`,
    faq_list_b_title_c: 'What are the account levels in the HanaGold app?',
    faq_list_b_title_des_c: `<h6>The HanaGold app has 3 account levels, arranged from low to high: </h6> <p> 1. User account <br /> 2. HanaGold Ambassador account <br /> 3. E-Franchise account </p>`,
    faq_list_b_title_d:
      'What is the difference between the three account levels?',
    faq_list_b_title_des_d: `<h6>1. User Account</h6> <p> - Only entitled to commission from the 'Refer a Friend' feature </p> <h6>2. HanaGold Ambassador Account</h6> <p> - Eligible for full business commission policy <br />- Receives 10% sales bonus when 'User' withdraws money from 'Commission Wallet' </p> <h6>3. E-Franchise Account</h6> <p> - Eligible for full business commission policy <br />- Receives 10% sales bonus when 'HanaGold Ambassador' withdraws money from 'Commission Wallet' <br />- Receives 10% sales bonus when 'User' withdraws money from 'Commission Wallet' </p>`,
    faq_list_b_title_e: 'How does the business work?',
    faq_list_b_title_des_e: `<p> E-Franchise partners will develop users and do business based on the features profit accumulation at the HanaGold application to receive the highest commission level. </p>`,
    faq_list_b_title_f: 'Where will I receive revenue?',
    faq_list_b_title_des_f: `<p> Online Franchise revenue will be paid to the 'Commission Wallet' </p>`,
    faq_list_b_title_g: `How does business work with the 'Commission Wallet' feature?`,
    faq_list_b_title_des_g: ` 
     
    <p> When 'User' and 'Business Ambassador' accounts withdraw money from the 'Commission Wallet', the 'Online Franchise' account will receive a 10% commission at the account level </p>`,
    faq_list_b_title_h:
      'What is the relationship between HanaGold Ambassadors and E-Franchise?',
    faq_list_b_title_des_h: `<p>It is the sales team that helps Online Franchise partners increase passive income from the lower line "User". Therefore, just by building this team, Online Franchise partners will receive commission for both "HanaGold Ambassador" and "User". </p>`,

    sign: `Sign Up`,
    sign_a: `Franchise Partner`,
    btn_b: 'Sign Up',
  },

  gold_price: {
    select_date: 'Choose date',
    title: 'GOLD PRICES TODAY',
    title_a: 'USD Exchange Rate (Vietcombank)',
    buy: 'BUY',
    sell: 'SELL',

    rank: 'CATEGORY',
    header_title_a: 'CLOSING PRICE',
    header_title_b: `TODAY'S CLOSING PRICE`,
    buy_a: 'Buy in',
    buy_b: 'Sell out',
    text: '(Unit: Million VND/tael)',
    text_a: 'BUY - SELL SPREAD',
    text_des: ' Supported by the Vietnam Gold Business Association',
    share: 'Share',
    down_a: 'Download',
    down_b: 'Loading',
  },

  rate: {
    select_date: 'Choose date',
    title: 'EXCHANGE RATES',

    title_a: 'Currency code',
    title_b: 'Currency name',
    title_c: 'Cash buying',
    title_d: 'Transfer buying',
    title_e: 'Selling',

    text_des: '*Source from Vietcombank',
    share: 'Share',
    down_a: 'Download',
    down_b: 'Loading',
  },

  news: {
    title: 'NEWS',
    read_more: 'See more',
    no_data: 'No data',
    view: 'views',
    hot: 'FEATURED NEWS',
  },

  app_header: {
    header_a: 'Good morning',
    header_b: 'Good evening',
    header_c: 'LOG OUT',
  },

  app_header_mb: {
    app_a: 'Gold 4.0',
    app_b: 'Products',
    app_c: 'Chart',
    app_d: 'Store',
    app_e: 'Other',
  },

  dashboard_gold: {
    gold_a: 'Gold available',
    gold_b: 'Money available',
    gold_c: 'Deposit',
    gold_d: 'Buy Gold',
    gold_e: 'Transfer gold',
    gold_f: 'Receive gold',
    gold_g: 'Withdraw money',
    gold_h: 'Sell gold',
    gold_i: 'Agent',
  },

  add_money: {
    add_a: 'Nạp Tiền',
    add_b_a: `Bạn đang có`,
    add_b_b: `yêu cầu nạp
    tiền chưa hoàn tất`,

    add_c_a: 'Tối thiểu từ',
    add_c_b: 'đến',

    add_d: 'PHƯƠNG THỨC THANH TOÁN',
    add_btn: 'Tiếp Tục',

    add_e: 'GỬI YÊU CẦU NẠP TIỀN',
    add_f: ` Vui lòng chuyển tiền ngân hàng tới số tài
    <br /> khoản và nội dung như sau. Sau đó nhấn “Quay lại” để
    trở ra`,
    add_g: 'Bank',
    add_h: 'Chi nhánh',
    add_i: 'Chủ tài khoản',
    add_k: 'Amount',
    add_l: 'Account number',
    add_m: 'Nội dung',
    add_n: ' QUAY LẠI',
    add_o: ' Hủy yêu cầu',

    add_suc_a: 'Tạo thành công',
    add_suc_b: 'Thời gian',
    add_suc_c: 'Transaction code',
    add_suc_d: 'Quay lại trang chủ',

    add_f_a: 'Giao dịch đang được xử lý',
    add_f_b: 'Thời gian',
    add_f_c: 'Transaction code',
    add_f_d: 'Quay lại trang chủ',
  },

  history_add: {
    title: ' Yêu Cầu Nạp Tiền',

    history_add_a: 'Transaction code',
    history_add_b: 'Chuyển qua ngân hàng',
    history_add_c: 'Chủ tài khoản',
    history_add_d: 'Amount',
    history_add_e: '   Xem thêm',
  },

  buy_sell: {
    title: ' Mua Bán Vàng',
    buy: 'Mua',
    sell: 'Bán',
    money: 'Tiền hiện có',
    gold: 'Vàng hiện có',
    gold_a: 'chỉ',
    gold_b: 'Số vàng',
    gold_c: 'Amount',
    gold_d: '  * Giá trị mua vàng chỉ từ:',
    gold_e: '   * Giá trị vàng quy đổi từ:',
    gold_f: '  Tỷ giá Mua vào',
    gold_g: 'Tỷ giá Bán ra',
    gold_h: 'Tiếp tục',

    ok: 'Chúc Mừng!',
    if: 'XEM THÔNG TIN',
    select: '   Chọn đối tác',
  },

  deliver_gold: {
    title: 'Chuyển Vàng',
    receiver: 'Người nhận',
    save: 'Lưu vào danh bạ',
    gold: 'Số vàng cần chuyển',
    avi: 'Khả dụng',
    accept: 'Xác nhận',
    suc: 'Giao dịch thành công',
    suc_a: 'Đã chuyển thành công',
    suc_b: 'Chỉ cho',
    con: 'TIẾP TỤC GIAO DỊCH',
    back: 'Quay lại trang chủ',
    contacts: 'Danh bạ',
    del_contacts: ' Xóa khỏi danh bạ',
  },

  withdraw: {
    title: 'Nhận vàng',
    gold: 'Vàng hiện có',
    money: 'Tiền hiện có',
    gold_a: 'Số vàng cần quy đổi',
    lowest: ' Rút thấp nhất',
    highest: ' Rút cao nhất',
    buy: 'Tỷ giá Mua vào',
    sell: 'Tỷ giá Bán ra',
    spend: 'Chi phí giao dịch',
    total: 'Tổng phí',
    con: 'Tiếp Tục',
    add: 'CHỌN ĐỊA CHỈ NHẬN HÀNG',
    phone: 'Phone number',
    next: 'Bước tiếp theo',
    inf: 'Delivery address information',
    att: 'LƯU Ý',
    att_a: ' Thời gian rút vàng sẽ được đại lý gửi khi xác nhận đơn hàng.',
    att_b: ' Vui lòng mang theo CMND để hoàn thành thủ tục nhận vàng.',
    accept: 'Xác nhận',
    suc: 'Giao dịch thành công',
    suc_text: 'Bạn đã gửi yêu cầu nhận vàng thành công',
    back: 'Quay lại trang chủ',

    fail: 'Giao dịch không thành công',
    fail_text: 'Giao dịch thất bại. Vui lòng thử lại',
    re: '   GIAO DỊCH LẠI',
  },

  withdraw_money: {
    title: ' Rút tiền',
    total: 'TỔNG SỐ TIỀN:',
    lowest: 'Tối thiểu từ',
    highest: 'Tối đa',

    to: 'đến',
    spend: 'Chi phí giao dịch',
    trans: 'giao dịch',
    con: 'Tiếp tục',
    acc: 'TÀI KHOẢN NHẬN',
    err: 'Bạn chưa liên kết tài khoản ngân hàng !',
    suc: 'thành công',
    req: 'Yêu cầu rút tiền',
    con_a: 'Tiếp tục giao dịch',
  },

  agency: {
    title: 'Đại lý',
    tab_a: 'Rút vàng',
    tab_b: 'Chuyển Vàng',
    tab_c: 'Hoa Hồng',

    quantity: 'Quantity',
    time: 'Thời gian',
    phone: 'Số điện thoại/Email',
    details: 'Chi tiết',
    suc: 'Thành công',
    code: 'Transaction code',
    gold: 'Số vàng',
    auth: 'Chủ tài khoản',
    spend: 'Transaction fee',
    free: 'Miễn phí',
    more: 'See more',
    money: 'Số tiền nhận được',
    gold_a: 'Số vàng mua',

    req_text: 'YÊU CẦU RÚT VÀNG',
    quantity_a: 'Số lượng rút',
    status: 'Trạng thái',
    name: 'Tên người rút',
    email: 'SĐT/Email',
    add: 'Address',
    date: 'Ngày rút',
    note: 'Ghi chú',
    reject: 'Từ chối',
    accept: 'Xác nhận',
    is_accept: ' Xác nhận Người dùng nhận vàng',
    back: 'Quay lại',
  },

  product: {
    title: 'Products',
    all: 'All',
    cat: 'Categories',
    item_a: 'Bracelets',
    item_b: 'Necklaces',
    item_c: 'Earrings',
    item_d: 'Pendants,',
    item_e: 'Rings,',
    item_f: 'Bracelets',

    price_a: '1 Million - 3 Million',
    price_b: '3 Million - 5 Million',
    price_c: '5 Million - 10 Million',
    price_d: 'Above 10 Million',
    price_f: 'All prices',
    price_e: 'All prices',

    price_text: ' price',

    cat_a: 'All',
    cat_b: '18k Gold',
    cat_c: 'Rose Gold',
    cat_d: 'Pure Silver',
    cat_e: 'Silver-plated',
    cat_f: 'Two-tone Silver',

    type_a: 'All materials',
    type_b: 'Gold',
    type_c: 'Silver',
    type_d: 'Diamond',
    type_e: 'Copper',

    res_a: 'result of',
    res_b: 'Products',
    more: 'See more',
  },

  chart: {
    title: 'Chart',
    overall: 'OVERVIEW',
    price: 'GOLD PRICES',
    news: 'NEWS',

    text_t: 'DOMESTIC GOLD PRICES',
    details: 'View details',
    table_a: 'LV • x10/WEIGHT',
    table_b: 'BUY',
    table_c: 'SELL',

    hot: 'FEATURED NEWS',
    all: 'View all',
    view: 'views',

    price_a: 'DOMESTIC GOLD PRICES (24K)',
    price_b: 'GOLD BAR PRICES',
  },

  store: {
    title: 'Store',
  },

  setting: {
    title: 'Settings',
    gen: 'GENERAL SETTINGS',
    sec: 'SECURITY',

    active: 'Verified',
    not_active: 'Not verified',

    profile: ' Profile',
    name: 'Full name',
    mail: 'Email',
    phone: 'Phone number',
    date: 'Date of birth',
    add: 'Address',
    save: 'SAVE CHANGES',

    ps: 'Change password',
    c_ps: 'Current password',
    n_ps: 'New password',
    r_n_ps: 'Confirm password,',
    btn: 'CHANGE PASSWORD',

    his: 'Transaction history',
    link: 'Link bank card',
    link_t: 'Bank Account',
    link_l: 'LINK BANK',
    btn_add: 'Add account',
    m_link_t: 'add new bank account',
    bank: 'Bank',
    e_bank: 'Click to select',
    p_bank: 'Search by bank,',
    n_bank: 'Account number',
    a_bank: 'Cardholder name',
    add_c: 'Add',
    back: 'Back',

    fr: 'Refer a friend',
    log: 'Log out',

    very_a: 'Account Verification (KYC)',
  },

  history_trans: {
    title: 'Transaction History',
    cat_a: 'Buy Gold',
    cat_b: 'SELL GOLD',
    cat_c: 'RECEIVE GOLD',
    cat_d: 'DEPOSIT MONEY',
    cat_e: 'WITHDRAW MONEY',
    cat_f: 'OTHER MONEY',
    cat_g: 'BUY ITEMS',

    // MUA VÀNG

    buy_a: 'Date',
    buy_b: 'Buy',
    buy_c: 'Buy price',
    buy_d: 'Transaction fee',
    buy_e: 'Free',
    buy_f: 'Total amount',

    no_data: 'No data',

    status_a: 'Pending approval',
    status_b: 'Canceled',
    status_c: 'Success',
    status_d: '--',

    sell_a: 'Date',
    sell_b: 'Sell',
    sell_c: 'Sell price',
    sell_d: 'Transaction fee',
    sell_e: 'Free',
    sell_f: 'Total amount',

    gold_a: 'Transaction code',
    gold_b: 'Gold quantity to withdraw',
    gold_c: 'Transaction fee',
    gold_d: 'Delivery address information',
    gold_e: 'Address',
    gold_f: 'Phone number',
    gold_g: 'Cancel gold receipt',

    pay_a: 'Source of money',
    pay_b: 'Description',
    pay_c: 'Amount',

    trans_a: 'Bank',
    trans_b: 'Account number',
    trans_c: 'Cancel withdrawal',

    mon_a: 'Sender information',
    mon_b: 'Quantity',
    mon_c: 'Canceled',
    mon_d: 'Total amount',
    mon_e: 'Cancel order',

    cancel_a: 'Sure you want to cancel the order',
    cancel_b: 'Want to change the delivery address',
    cancel_c: 'Want to change the voucher code',
    cancel_d: 'Want to change the products in the order',
    cancel_e: 'Payment process too complicated',
    cancel_f: 'Other reasons',
    cancel_g: 'Cancel order',
    cancel_h: 'Back',

    modal_a: 'Sure you want to cancel the gold receipt request?',
    modal_b: 'Cancel',
    modal_c: 'Back',
  },

  referral: {
    text_title: 'Introduction',
    ref_a: 'Introduced (person)',
    ref_b: 'Success (person)',
    ref_c: 'Reward (gold)',

    ref_d: 'Introduce now',
    ref_e: 'Share link',
    ref_f: 'Referral code',
    ref_g: 'Referral list',

    all: 'All',
    active: 'VERIFIED',
    non_active: 'UNVERIFIED',
  },

  message: {
    suc_a: 'Successfully copied receiving account number',
    suc_b: 'Successfully copied amount',
    suc_c: 'Successfully copied transfer content',
    suc_d: 'Successfully confirmed',
    suc_e: 'Successfully canceled',
    suc_f: 'Successfully saved',
    suc_g: 'Successfully submitted request',
    suc_h: 'Added to cart successfullyg',
    suc_i: 'Deleted',
    suc_k: 'You have successfully canceled the transaction',
    suc_l: 'with the amount of',
    suc_m: 'Successfully copied deposit account number!',
    suc_n: 'Successfully copied deposit amount!',
    suc_o: 'Successfully copied deposit transaction content!',
    suc_u: 'Password changed successfully.',
    suc_p: 'Successfully added',
    suc_q: 'Referral code copied successfully.',
    suc_r: 'Successfully added address',
    suc_s: 'Successfully deleted address',

    err: 'Failure',
    err_a: 'Failed to save.',
    err_b: `You don't have any products, so you can't proceed to the next step`,
    err_c: 'File size > 1 MB!',
    err_d: 'You have not entered the correct amount!',
    err_e: 'Confirmation failed',
    err_f: 'Please enter a quantity greater than 0',
    err_g: 'New password does not match!',
    err_h: 'At least one address is required!',
    err_i: 'No products in the cart!',
    err_k: 'Password cannot be empty!',
    err_l: 'New password does not match the confirmation password!',
    err_m: 'Please enter the correct',
    err_n: 'Please enter the account number',

    val_a: 'Incorrect email format!',
    val_b: 'Email not entered!',
    val_c: 'Phone number not entered!',
    val_d: 'Password not entered!',
    val_e: 'Passwords do not match!',
    val_f: 'Email not entered!',
    val_g: 'Incorrect date of birth format',
    val_h: 'Please enter your date of birth',
    val_i: 'Select a recipient!',
    val_k: 'Account number not entered!',
    val_l: 'Account does not exist',
    val_m: 'Name not entered!',
    val_n: 'Account not entered!',
    val_o: 'Incorrect account format!',
    val_p: 'OTP not entered!',
    val_q: 'OTP code must be 6 characters!',
    val_r: 'Incorrect agent name format!',
    val_s: 'Incorrect address format!',
    val_t: 'Password cannot be empty!',
    val_u: "'Please enter the address''",

    pla_a: 'Full name',
    pla_b: 'Full name',
    pla_c: 'What product are you looking for?',
    pla_d: 'Email address',
    pla_e: 'Phone number',
    pla_f: 'Content',
    pla_g: 'Address',
    pla_h: 'Enter amount',
    pla_i: '+ Add email',
    pla_k: '+ Add email',
    pla_l: 'Enter gold amount',
    pla_m: 'What are you looking for?',
    pla_n: 'Enter phone number or email',
    pla_o: 'Enter password',
    pla_p: 'Verify password',
    pla_q: 'Enter new password',
    pla_r: 'VERIFICATION CODE',
    pla_s: 'Enter OTP code',
    pla_t: 'Current job',
    pla_u: 'Search...',
    pla_v: 'Discount code',
    pla_w: 'Your message',
    pla_x: 'Date of birth: DD/MM/YYYY',
    pla_y: 'Store',
  },

  tutorial_purchase: {
    title: 'Shopping Guide',
    des: 'Shopping Guide',
    des_a:
      'With the motto of Safety - Convenience - Speed, HanaGold strives to provide the best technology-driven buying and selling experiences for jewelry and fine arts enthusiasts. Customers can acquire HanaGold products through the following methods:',
    step_a: `<p>Method 1: For jewelry and fine art products</p>
    <span>
      Customers can place orders directly on the website www.hanagold.vn or on e-commerce platforms such as "HanaGold Official" on Tiki, Lazada, Shopee.
    </span>`,

    step_b: `<p>Method 2: For HanaGold Accumulated Gold products</p>
    <span>
      Customers can download the HanaGold App on CHPlay and App store to purchase and accumulate gold. Additionally, customers can choose to accumulate gold through the VNDC wallet pro App (HanaGold's partner channel).
    </span>`,
    step_c: `<p>Method 3: Direct contact</p>
    <span>
      For a quicker process, customers can call directly to the hotline 0889028009 to place orders or send an email to contact@hanagold.vn.
    </span>`,
  },

  tutorial_payment: {
    title: 'Payment Guide',
    des:
      'At HanaGold, customers are provided with various online payment methods, offering flexibility and convenience for product and service transactions, including direct cash payment, ATM payment (with registered Internet Banking), and Cash on Delivery (COD).',

    step_a: `<h3>Cash Payment</h3>
    <p>
      This method is applicable for customers purchasing goods directly at HanaGold store. The cashier will assist you throughout the process.
    </p>`,
    step_b: `<h3>Online Payment via ATM Card (with Internet Banking registration)</h3>
    <p>
      This method is for customers ordering and buying jewelry products on the HanaGold website. The process is as follows:
    </p>
    <p><b>Step 1:</b> Select your favorite product and add it to the cart</p>
    <p><b>Step 2:</b> Choose the online payment method via internet banking</p>
    <p><b>Step 3:</b> Fill in the required information and authenticate the transaction</p>
    <p><b>Step 4:</b> Complete the order payment and wait for order confirmation</p>`,

    step_c: `<h3>Refund Policy for Online Payments</h3>
    <p><b>Balance, Product Refund</b></p>
    <p> 
      In case customers have successfully made online payments but have a balance or need to return the product, HanaGold will refund as follows:
    </p>
    <ul>
      <li>
        <p>
          For ATM cards: HanaGold will refund to the customer's provided account, and customers will receive the refund within 5 - 7 days (excluding Saturdays, Sundays, and holidays) from the date HanaGold receives the customer's request. If no money is received after this period, customers should contact the bank for resolution. HanaGold will provide assistance with information (if available).
        </p>
      </li>
      <li>
        <p>
          For credit cards:  HanaGold will refund to the customer's provided account, and customers will receive the refund within 5 - 7 days (excluding Saturdays, Sundays, and holidays) from the date HanaGold receives the customer's request. If no money is received after this period, customers should contact the bank for resolution. HanaGold will provide assistance with information (if available).
        </p>
      </li>
    </ul>`,

    step_d: `<p><b>HanaGold does not receive payment but the customer is debited</b></p>
    <p>
      In case of unsuccessful payment (the bank receiving the payment does not record it), but the customer's account is debited, customers should contact the bank regarding the amount deducted for the purchase.
    </p>`,
    step_e: `<p><b>Customer provides incorrect content, not the payer</b></p>
    <p>
      If the customer is not the direct payer (the customer asks a relative to pay on their behalf), please follow the instructions for content or order number (if any) to ensure proper processing by HanaGold. Incorrectly provided content or order numbers will be processed within 3 - 5 working days (excluding Saturdays, Sundays, and holidays).
    </p>`,
  },

  tutorial_preserve: {
    title: 'Jewelry Care Guide',
    step_a: `<p><b>Pearl Jewelry</b></p>
    <p>
      Due to the organic coating of pearls, avoid contact with perfumes, skincare products, nail polish remover, hair spray, lemon, vinegar, and other volatile chemicals. It's recommended to rinse with warm water (avoid using a brush) and gently wipe with a soft cotton cloth for natural luster.
    </p>`,
    step_b: `<p><b>Precious Stone Jewelry (Ruby, Sapphire, etc.)</b></p>
    <p>
      Avoid contact with hard objects to prevent scratches and maintain the beauty and value of the stones. Clean these products with a soft brush and warm soapy water. To enhance the shine, you can wipe them with colorless oil or beeswax.
    </p>`,
    step_c: `<p><b>Emerald, Opal Jewelry</b></p>
    <p>
      Store these items like precious stones. Due to their special characteristics, they require careful handling during cleaning. For any maintenance needs, visit HanaGold stores for optimal care.
    </p>`,
    step_d: `<p><b>Diamond Jewelry</b></p>
    <p>
      Diamonds are resistant to normal wear and tear, but they require regular cleaning to maintain perpetual brilliance. Soak in warm soapy water for about 30 minutes, rinse with clean water, and dry with a soft cloth.
    </p>`,
    step_e: `<p><b>White Gold Jewelry</b></p>
    <p>
      White gold, coated with rhodium for a bright appearance, may fade over time. To keep it radiant, visit HanaGold every 4 to 6 months for refurbishing.
    </p>`,
    step_f: `<p>
    <b>Gold Jewelry</b>
  </p>
  <p>When using gold jewelry, you should note:</p>
  <p>
    Avoid exposing gold to mercury, hair spray, hair dye, and some cosmetics, as these substances can cause the gold to discolor.
  </p>
  <p>
    To keep gold shining, use a soft toothbrush dipped in soap or toothpaste to brush both inside and outside the jewelry to remove dirt and sweat. When the jewelry is wet, do not let it air dry; instead, use a cotton cloth or soft paper to pat it dry.
  </p>
  <p>
    Over time, jewelry may lose its shine, and the gold color may fade. The only way to restore the original shine is to clean, replate, and polish using specialized jewelry tools. All HanaGold stores provide free ultrasonic cleaning services for HanaGold products. For re-plating services, wedding ring products are free, while other product lines may incur a reasonable fee depending on the product type.
  </p>`,

    step_g: `<p>
    <b>Silver Jewelry</b>
  </p>
  <p>
    Silver jewelry worn for an extended period may become dull. Silver oxidizes when exposed to air, strong light, and chemicals such as hair spray, perfume, bleach, and seawater.
  </p>
  <p>
    To keep silver jewelry shiny, soak and gently wash it in mild soapy water (dishwashing soap, cup, or bowl water) mixed with clean water. Then rinse with clean water and dry thoroughly with a soft cloth. Never use strong cleaning solutions.
  </p>
  <p>
    To ensure the beauty and longevity of silver jewelry, store it carefully, and when wearing, avoid impact or the use of any tools that directly impact the jewelry.
  </p>`,
  },

  tutorial_measuresize: {
    title: 'Jewelry Size Measurement Guide',
    t_a: 'Ring Size Measurement',
    t_b:
      'With this guide, you can easily shop for gold rings, silver rings, wedding rings, diamond rings, or couple rings online without any worries',
    step_a: `  <li>
    <p><b>Step 1:</b> Choose a ring that fits the desired finger</p>
  </li>
  <li>
    <p><b>Step 2:</b> Use a ruler to measure the inside diameter of the ring</p>
  </li>
  <li>
    <p><b>Step 3:</b> Compare with the standard ring size chart</p>
  </li>
  <li>
    <p><b>Step 4:</b> Fill in the standard ring size (Size number...) in the blank when placing an online order.</p>
  </li>`,

    step_b: `<h3>Measure Ring Size with Paper and Ruler</h3>
    <ul>
      <li>
        <p><b>Step 1:</b> Prepare a ruler, scissors, a pen, and a piece of paper</p>
      </li>
      <li>
        <p><b>Step 2:</b> Cut a piece of paper about 10 cm long and 1 cm wide</p>
      </li>
      <li>
        <p><b>Step 3:</b> Use the cut paper to wrap around the desired finger</p>
      </li>
      <li>
        <p><b>Step 4:</b> Mark the intersection point</p>
      </li>
      <li>
        <p><b>Step 5:</b> Measure the length of the paper from the starting point to the marked part. Divide the result by 3.14. Then compare with the standard RING SIZE CHART</p>
      </li>
    </ul>`,

    step_c: `<h3>Notes on Measuring</h3>
    <h4>1/ Finger Size Depends on Temperature</h4>
    <p>
      Temperature can affect the accuracy of ring size measurement. In cold weather, your fingers may be smaller than usual, so you should add 1 mm to the circumference. In hot weather, subtract 1 mm. If your finger joints are larger, measure the circumference near the joint (not on the joint) so that the ring can be easily put on without slipping off.
    </p>
    
    <h4>2/ Pay Attention to Ring Thickness</h4>
    <p>
      The thickness of the ring also affects individual size. Different materials like gold or silver may have different thicknesses. Be attentive while measuring and adjust the size accordingly to ensure comfort without being too tight.
    </p>
    
    <h4>3/ Pay Attention to Finger Joints</h4>
    <p>
      Finger joints may be larger than at the base of the finger. Measure the size at both the joint and the base, then take the average to choose the appropriate ring size. This ensures comfort and prevents discomfort when bending or folding the fingers.
    </p>
    
    <p>
      In the case of larger finger joints, measure the circumference near the joint (not on the joint) so that the ring is easy to put on and does not slip off.
    </p>
    
    <h4>4/ Measure Multiple Times for Accuracy</h4>
    <p>
      When measuring hand size, there may be errors, so be careful to measure 3-4 times to find the most accurate measurement. Following this method, the size you measure will be accurate from 85-95%.
    </p>`,

    t_c: 'Bracelet Size Guide',
    t_d: 'The length of the bracelet is 17 cm, corresponding to size 17',

    step_d: `<li>
    <h4>Common Bracelet Sizes for Women: Size 16-18</h4>
    <p>16 cm - 17 cm - 18 cm</p>
  </li>
  <li>
    <h4>Common Anklet Sizes for Women: Size 23-25</h4>
    <p>23 cm - 24 cm - 25 cm</p>
  </li>
  <li>
    <h4>Common Anklet Sizes for Children: Size 13-15</h4>
    <p>13 cm - 14 cm - 15 cm</p>
  </li>`,

    step_e: `<p>
    <b>Note:</b>
  </p>
  <p>
    - Common bracelet sizes from HanaGold for women are usually size 16, 17, 18; for women's anklets, it's size 23-25, and for children's bracelets, it's size 13-15.
  </p>
  <p>
    - If the measured bracelet size is not available, HanaGold will custom make it within a maximum of 2 weeks, applicable only to gold jewelry.
  </p>`,

    step_f: `<p>
    <b>Manual Measurement:</b>
  </p>
  <p>
    - If you don't have another bracelet to measure, HanaGold suggests a method, but note that it may not provide accurate results, but can be used.
  </p>
  <p>
    - Use a small piece of paper as illustrated, wrap it tightly around your wrist/ankle, measure the length with a ruler as instructed in method 2. After selecting the bracelet size, HanaGold's staff will call to confirm your request and provide specific advice to meet your requirements.
  </p>`,

    t_e: 'Ring Size Guide',
    t_f: 'The diameter inside the ring (ring size)',

    step_g: `<li>
    <h4>Ring Sizes for Women: Size 52-55</h4>
    <p>52 mm - 53 mm - 54 mm - 55 mm</p>
  </li>
  <li>
    <h4>Ring Sizes for Men: Size 56-60</h4>
    <p>56 mm - 57 mm - 58 mm - 59 mm - 60 mm</p>
  </li>
  <li>
    <h4>Ring Sizes for Children: Size 36-42</h4>
    <p>36 mm - 38 mm - Other sizes - 42 mm</p>
  </li>`,

    step_h: `<p>
    <b>Measure Based on Ring:</b>
  </p>
  
  <p>
    <b>Step 1:</b> Use a ruler to measure the diameter inside the bracelet.
  </p>
  <p>
    <b>Step 2:</b> After obtaining the size, compare the mm of the ruler with the diameter size on the size chart above.
  </p>
  <p>
    <b>Step 3:</b> Your bracelet size is the number written below the circle. After selecting the bracelet size, HanaGold's staff will call to confirm your request and provide specific advice to meet your requirements.
  </p>`,

    step_i: `<p>
    <b>Manual Measurement:</b>
  </p>
  <p>
    - For a round bracelet:
  </p>
  <p>
    <b>Step 1: </b> Use a string (or tape/measuring tape/small paper strip) to wrap around your wrist where you want to wear the bracelet, mark the meeting point.
  </p>
  <p>
    <b>Step 2:</b> Measure the length of the string between the meeting points.
  </p>
  <p>
    <b>Step 3:</b> Divide that length by 3.14 to get the bracelet diameter.
  </p>
  <p>
    <b>Step 4:</b> Compare the diameter you measured (in mm) with the size chart above. Your bracelet size corresponds to the number written below the circle.
  </p>`,

    step_k: `<p>
    <b>Oval Bracelet:</b>
  </p>
  <p>You can measure the width of your wrist.</p>
  <p>Your bracelet size is the number written below the circle.
  </p>`,

    t_g: `Necklace Size Guide`,
    t_h: `The length of the chain is 40 cm, corresponding to size 40.`,

    step_l: `<h4>Common Chain Sizes</h4>
    <p>40 cm - 41 cm - 42 cm - 43 cm - 44 cm - 45 cm</p>
    
    <p>
      It is recommended to have another necklace/chain to measure. The most accurate method is to use a necklace/chain with a similar width and style to the one you intend to buy and follow these steps:
    </p>
    
    <p>- Use a ruler to measure the length of the chain.</p>
    <p>
      - After obtaining the size, compare the cm of the ruler with the illustrated size on the chart above.
    </p>
    <p>
      - Your chain size is the number written below the straight line.
    </p>`,
    step_n: `<p>
    <b>Manual Measurement:</b>
  </p>
  <p>
    - If you don't have another necklace/chain to measure, HanaGold introduces a method, but note that applying this method may not provide accurate results, but can be used.
  </p>
  <p>
    - Use a small piece of paper, wrap it around your neck following the desired necklace/chain, then measure the length with a ruler as instructed in method 2. After selecting the necklace/chain size, HanaGold's staff will call to confirm your request and provide specific advice to meet your requirements.`,
  },

  tutorial_recieve: {
    title: 'DELIVERY POLICY',

    step_a: `<h2>
    Direct Delivery at HanaGold Store and Showrooms
  </h2>
  <p>
  Customers purchasing at HanaGold store and authorized showrooms will receive their orders upon completion of payment. Products undergo thorough inspection and confirmation before leaving the store.
  </p>`,

    step_b: `<h2>Home Delivery</h2>
    <p>Products eligible for home delivery:</p>
    <ul>
        <li>
            <p>Silver jewelry products</p>
        </li>
        <li>
            <p>
                Diamond gift products, including gold-plated feng shui items, commemorative cups, gold-plated medals, gold-plated paintings, etc.
            </p>
        </li>
    </ul>
    <p>
        *Note: HanaGold provides nationwide delivery for all orders. Delivery time and shipping costs depend on the chosen delivery service.
    </p>`,

    step_c: `<h2>PRODUCT INSURANCE SERVICE</h2>
    <p>
        <b>100% Goods Insurance and Delivery Guarantee Service</b>
    </p>
    <ul>
        <li>
            <p>
                To protect customer rights when purchasing high-value jewelry, all HanaGold products shipped through transportation partners are insured by HanaGold.
            </p>
        </li>
        <li>
            <p>
                We commit to a 100% compensation of the goods' value in case of loss.
            </p>
        </li>
    </ul>`,

    step_d: `  <b>Packaging Procedure at HanaGold</b>
    </p>
    <ul>
        <li>
            <p>
                All HanaGold products undergo quality checks before double-sealed packaging.
            </p>
        </li>
        <li>
            <p>
                Layer 1: Your product is placed in the HanaGold jewelry box and sealed with the first layer using the HanaGold Breakable Seal*.
            </p>
        </li>
        <li>
            <p>
                Layer 2: The shipping box is securely packed, sealed with 2 HanaGold Breakable Seals on each lid, and carefully taped.
            </p>
        </li>
    </ul>
    <p>
        <b>Note:</b>
    </p>
    <ul>
        <li>
            <p>
                During inspection, customers are advised to record a video to facilitate resolution of any arising issues.
            </p>
        </li>
        <li>
            <p>
                *Breakable Seal: A special seal used to ensure the product remains unopened. It is fragile, and attempting to remove it will cause it to break.
            </p>
        </li>
    </ul>
    
    <p>
        <b>Delivery Time</b>
    </p>
    <ul>
        <li>
            <p>Ho Chi Minh City, Hanoi: 1-4 days.</p>
        </li>
        <li>
            <p>Other provinces and cities: 2-6 days.</p>
        </li>
        <li>
            <p>
                Delivery time starts from the successful order confirmation by HanaGold's consultant.
            </p>
        </li>
        <li>
            <p>
                The provided delivery time is indicative; actual delivery may vary based on product inventory, delivery address, and uncontrollable factors (e.g., natural disasters, floods, vehicle issues).
            </p>
        </li>
    </ul>`,
    step_e: `<p>
    <b>Delivery Time</b>
</p>
<ul>
    <li>
        <p>Ho Chi Minh City, Hanoi: 1-4 days.</p>
    </li>
    <li>
        <p>Other provinces and cities: 2-6 days.</p>
    </li>
    <li>
        <p>
            Delivery time starts from the successful order confirmation by HanaGold's consultant.
        </p>
    </li>
    <li>
        <p>
            The provided delivery time is indicative; actual delivery may vary based on product inventory, delivery address, and uncontrollable factors (e.g., natural disasters, floods, vehicle issues).
        </p>
    </li>
</ul>`,

    step_f: ` <h4>
    Policy for Inspecting Goods Before Acceptance
  </h4>
  <p>
  To meet customer needs and maximize customer rights when using our services, HanaGold has implemented a policy supporting the inspection of goods upon delivery.
</p>
<p>
  When receiving items from the delivery staff, customers are encouraged to check the intactness of the box, with both seals intact. Customers can open the box to inspect the contents, and recording a video during the inspection is recommended for easy issue resolution.
</p>
<p>
  HanaGold ensures that the products sent to customers have undergone quality checks. In the rare case that the received product is defective, damaged, or not as described, HanaGold commits to protecting the customer through return and warranty policies.
</p>`,
  },

  login: {
    log: 'LOGIN',
    log_p: `Don't have an account with HanaGold?`,
    re: 'Sign up now',
    remem: 'Remember',
    forgot: 'Forgot password',
    re_a: 'Sign up',
    text: 'Invitation from',
    re_b: 'Already have an account with HanaGold?',
    log_b: 'Log in now',
    create: 'CREATE ACCOUNT',
    code: 'VERIFICATION CODE',
    code_text:
      'Please enter the verification code just sent to your email/phone below',
    suc: 'Complete',
    re_send: 'RESEND VERIFICATION CODE',
    suc_b: 'Success',
    text_b: `Invite you to download the HanaGold app on the App Store <br /> or CH Play to log in and receive rewards`,
    down: 'DOWNLOAD HANAGOLD APP',
    more: 'LEARN MORE ABOUT HANAGOLD',
    p_a: 'Forgot password',
    p_b: 'Please enter your account',
    p_c: 'Please enter the OTP code sent to your email/phone',
    p_d: 'Confirm',
    p_e: 'Send OTP code',
    p_f: 'Change password',
    p_g: 'RESEND VERIFICATION CODE',
  },

  info: {
    name_a: 'Ms. Nguyen Phi Van',
    name_b: 'Mr. Nguyen Tuan Quynh',
    in_a: `
    <h3>Ms. Nguyen Phi Van</h3>
    <h6>International Franchise Expert</h6>
    <p>
    Nguyễn Phi Van has held senior positions in brand management,
    retail, franchising, and business development for major
    corporations in Asian, Middle Eastern, African, and Eastern European markets.
    </p>
    <h4>Education</h4>"
    <p>- MBA graduate in Australia.</p>
    
    <h4>Experience</h4>
    <p>
    Phi Van has been involved in numerous government projects related to
    business development and innovative economy in Southeast Asia. She is
    currently an advisor for Project 844 on the development of the startup
    ecosystem in Vietnam, the chairwoman of the Southeast Asia Angel
    Investor Network, and the high-level representative of Vietnam at the
    global angel investment forum.
    <br />
    <br />
    Additionally, she is also an investor with a portfolio of 24 companies.
    Phi Van received the 2015 International Retail Leadership Award from
    the Global Retail Association, the 2017 & 2018 Outstanding Global
    Retail Leadership Awards, and the 2019 ASEAN Outstanding Entrepreneur
    Award. She was also listed in the Top 100 Most Influential Women in
    Global Franchising in 2019 & 2020.
    <br />
    <br />
    Phi Van is the author of several books in English and Vietnamese,
    including Entrepreneurial Franchising - The Short Path to the World,
    Breaking the Glass Ceiling, Go Global - An MSME Guide to Global
    Franchising, I Future & The World, I Search for Myself, Nym -
    My Future Self, and the latest, Opening the Future.
    </p>
    
    <h4>Role at HanaGold</h4>
    <p>- Senior Advisor on Franchising</p>
   `,
    in_b: `
    <h3>Mr. Nguyen Tuan Quynh</h3>
    <h6>CEO of Go Global Franchise Fund</h6>
    <p>
      Entrepreneur Nguyen Tuan Quynh, born on September 7, 1972, in Binh Duong, and raised in Ho Chi Minh City. He currently serves as the Chairman of the Board of Directors of Saigon Books Joint Stock Company.
    </p>
    <h4>Education</h4>
    <p>
      - Ph.D. in Business Administration
      <br />
      <br />
      - Outstanding student in scientific research at the University of Economics Ho Chi Minh City.
    </p>
    <h4>Experience</h4>
    <p>
      Entrepreneur Nguyen Tuan Quynh is the Vice Chairman of the Ho Chi Minh City Young Entrepreneurs Association, a lecturer, and a judge in numerous startup competitions. With 22 years of experience, he has led and managed various large enterprises such as Saigon Petro, Saigon Gas, Vina Gas, SFC, PNJ, PNC, Alpha Books, before establishing and leading Saigon Books since 2016. Additionally, he is a university lecturer, speaker, and judge in various seminars and startup competitions.
      <br />
      <br />
      Apart from being a beloved entrepreneur, while serving as the CEO of Saigon Fuel Company (SFC), Mr. Nguyen Tuan Quynh is also a familiar writer to readers. He won the first prize in the Tuoi Tre Newspaper essay writing competition in 2011, 2012; and the first prize in the Ho Chi Minh City Press, editorial category, in 2017.
      <br />
      <br />
      He is the author of three books: "Living Proactively," published in 2014; "Living Positively to Love," published in 2016; and "Just Fly, You Will Soar" (co-authored), published in 2018.
    </p>
    <h4>Role at HanaGold</h4>
    <p>- CEO of Go Global Franchise Fund</p>
   `,
  },

  breadcrumb_home: 'Home',

  gold_tap: {
    heading_1: 'Pioneering the Trend of',
    heading_2: 'GOLD Accumulation',
    description:
      'Start harvesting GOLD for free, using it within the HanaGold ecosystem.',
    button_search: 'Learn more',
    start_harvest: 'Start Harvesting GOLD',
    scan_QR:
      'Scan the QR Code or type “Gold Tap Tap” in the search bar on the Telegram app.',
    benefit: 'Benefits of Using GOLD in the Ecosystem',
    list_benefit: {
      benefit_title_1: 'Mint NFT',
      benefit_description_1:
        'Users can create unique and valuable NFTs on the TON blockchain.',
      benefit_title_2: 'Upgrade to VIP Status',
      benefit_description_2: 'Use GOLD tokens to exchange for VIP privileges.',
      benefit_title_3: 'Redeem E-Vouchers',
      benefit_description_3: 'Receive reward E-Vouchers from HanaGold.',
      benefit_title_4: 'Exchange',
      benefit_description_4: 'Users can use GOLD to exchange for XAU, HNG.',
      benefit_title_5: 'Trade on Exchange',
      benefit_description_5: 'Users can trade GOLD on listed exchanges.',
    },
    define_description:
      "Gold is HanaGold's digital asset for the community, earned for free by tapping the screen and completing daily tasks.",
    roadmap: 'Development Roadmap',
    features_title: 'Key Features',
    list_features: {
      feature_1: 'Tap & Earn GOLD for free!',
      feature_2: 'Increase GOLD when farming and completing tasks',
      feature_3: 'Receive huge rewards with your team!',
    },
    guides_1: 'User Guide',
    guides_2: '',
    start_now: 'Start now',
    list_steps: {
      title_step_1: 'STEP 01',
      description_step_1:
        'Access Telegram. If not, download & register an account.',
      title_step_2: 'STEP 02',
      description_step_2: 'Type “Gold Tap Tap” in the search bar',
      title_step_3: 'STEP 03',
      description_step_3: 'Start harvesting GOLD',
    },
    phase: {
      step: 'PHASE',
      important: 'IMPORTANT',
      t1: 'Launch - Initiate',
      d1: 'Earn free GOLD Tap Tap',
      t2: '1 million users',
      d2: 'Receive GOLD',
      t3: '5 million users',
      t4: '10 million users',
      d4: 'Listed on exchanges',
      t5: '20 million users',
      d5:
        'Use GOLD to redeem E-Vouchers, pay VIP fees within the HanaGold ecosystem',
    },
    use: 'Use',
  },
  priority: {
    baner_title: 'Hanagold priority',
    baner_subtitle: 'Discover',
    discover_title_line_1: 'Explore',
    discover_title_line_2: 'Exquisite Privileges',
    discover_subtitle: 'Priority Customers',
    discover_description:
      'HanaGold Priority Service is exclusively designed for premium individual customers of HanaGold Precious Metals Joint Stock Company.',
    discover_item_1_title: 'Gold accumulation platform',
    discover_item_1_description:
      'Delivering a pioneering gold accumulation experience in the 4.0 digital era.',
    discover_item_2_title: 'Financial solutions',
    discover_item_2_description:
      "Fully customized to meet each customer's needs",
    discover_item_3_title: 'Optimal protection',
    discover_item_3_description:
      'Maximizing financial resources, experiencing premium and secure services.',
    level_title: 'Account Levels',
    level_description:
      "HanaGold Priority is refined to the highest standards to elevate the luxury experience exclusively for HanaGold's premium customers.",
    level_label: 'LEVEL',
    solution_title: 'Enjoy Comprehensive Solutions Offered By HanaGold',
    solution_item_1_title: 'Exclusive stock purchase rights',
    solution_item_1_description:
      "Priority customers are entitled to register to purchase HanaGold shares under preferential conditions, investing in the company's growth.",
    solution_item_3_title: 'Exclusive financial benefits',
    solution_item_3_description:
      'Experience special benefits on fees and financial products, helping you save and optimize your financial transactions.',
    solution_item_2_title: 'Exclusive Priority Gold Card ownership',
    solution_item_2_description:
      "Granted a special Priority Gold Card, offering superior benefits and privileges, affirming the customer's status and prestige.",
    solution_item_4_title: 'Special care and consultation',
    solution_item_4_description:
      'Customers will receive care and consultation from the dedicated HanaGold team via a 24/7 hotline to meet all essential needs.',
    question_title: 'Frequently Asked Questions',
    question_item_1_title: 'What is HanaGold Priority?',
    question_item_1_description:
      "HanaGold Priority is an exclusive service designed for HanaGold's priority customers, offering a range of special benefits and privileges throughout their product and service experience.",
    question_item_2_title: 'Who can become a HanaGold Priority customer?',
    question_item_2_description:
      "Customers who meet HanaGold's purchasing and service usage criteria will be invited to join the HanaGold Priority program.",
    question_item_3_title: 'What are the benefits of HanaGold Priority?',
    question_item_3_description:
      'Priority customer service and care; access to special promotions and exclusive offers; participation in exclusive events organized by HanaGold.',
    question_item_4_title:
      'What are the requirements to register for HanaGold Priority?',
    question_item_4_description:
      'Customers need to meet specific purchasing and service usage criteria over designated periods to qualify for HanaGold Priority registration.',
    question_item_5_title: 'How can I register to become HanaGold Priority?',
    question_item_5_description:
      'You can request consultation on the website or register directly via the HanaGold app. On the menu bar, select "Other," then choose "Priority." You can upgrade to any tier.',
    question_item_6_title:
      'Do I need to pay any fees when becoming a priority customer?',
    question_item_6_description:
      "HanaGold Priority is a service exclusively for HanaGold's priority customers, providing various special benefits and privileges throughout the product and service experience.",
    question_item_7_title:
      'Can I share the benefits of HanaGold Priority with others?',
    question_item_7_description:
      'HanaGold Priority benefits are exclusive to verified priority account holders and cannot be transferred to others.',
    question_item_8_title: ' How long is the HanaGold Priority valid?',
    question_item_8_description:
      'The HanaGold Priority has indefinite validity. Customers only need to maintain the criteria according to policy.',
    question_item_9_title:
      'Who should I contact if I have issues or questions about HanaGold Priority?',
    question_item_9_description:
      'HanaGold Priority provides a 24/7 hotline and a dedicated email address for priority customers.',
    question_item_10_title: 'Why should I join HanaGold Priority?',
    question_item_10_description:
      'Joining HanaGold Priority provides exceptional benefits, including priority service, exclusive promotions and offers, and the opportunity to participate in special events organized by HanaGold.',
    form_title: 'Priority Customer Form',
    form_subtitle: 'Register for Consultation',
    form_name: 'Full Name',
    form_email: 'Email Address',
    form_phone: 'Phone Number',
    form_work: 'Current Job',
    form_btn: 'Register',
    sucess_form: 'Register success',
    level: 'LEVEL',
    invalid_email: 'Email is not correct',
    invalid_phone: 'Số điện thoại is not correct',
    required: 'is required'
  },

  business_solutions: {
    title: 'Business Solution',
    banner_title: 'Diverse and Flexible  for Businesses',
    banner_title_1: 'Reward Solutions',
    banner_title_2: 'Diverse and Flexible for Businesses',
    about_title: 'About Us',
    banner_description: 'For businesses, building long-term relationships with customers and motivating employees are two key factors for sustainable growth.',
    about_description_1: 'HanaGold Gold, Silver and Gemstone Joint Stock Company, established in 2020, is an innovative startup enterprise in the field of gold, silver and gemstones.',
    about_description_2: 'We apply 4.0 technology to gold business, creating a breakthrough in the industry. HanaGold develops an innovative model, ensuring asset value and bringing peace of mind to customers.',
    about_description_3: 'HanaGold Gold, Silver and Gemstone Joint Stock Company was established in 2020 and is an innovative startup enterprise in the field of gold, silver and gemstones. We apply 4.0 technology in gold business activities (4.0 technology gold shop).',
    about_description_4: 'Potential in the 4.0 technology era along with the need to use and invest in the gold market, HanaGold quickly seized the opportunity to develop the gold industry in Vietnam according to an improved model compared to the traditional one, creating a breakthrough. for gold business in Vietnam.',
    about_description_5: 'Our mission is to ensure the value of your property and bring peace of mind when engaging.',
    dedicated_solution_1: 'Tailored Solutions for',
    dedicated_solution_2: 'Businesses',
    hanagold_reward: {
      description_1: 'HanaGold Reward is the perfect solution for giving hot rewards, Tet rewards, etc. to show recognition, gratitude and retention of talented people.',
      description_2: 'Depending on each capacity, level and work results, businesses will flexibly choose more or less bonuses.'
    },
    hanagold_loyalty: {
      description_1: 'HanaGold Loyalty is a customer gratitude solution, used in communication campaigns, thereby building and creating value with loyal customers.',
      description_2: 'Gold gifts not only carry high material value, but also show luxury and sophistication.'
    },
    survey_report: {
      title_1: 'Survey Report',
      title_2: 'of Companies Rewarding with Cash and Gold',
      description: 'Follow Bucketlist Rewards (2024)',
      sastify: {
        sastify_1: 'Satisfied',
        sastify_2: 'Increase productivity',
        sastify_3: 'Increase team productivity'
      },
      unsastify: {
        unsastify_1: 'Unsatisfied',
        unsastify_2: 'Unproductive',
        unsastify_3: 'Not increase productivity'
      },
      description_1: 'Employee satisfaction rate when rewarded with cash and gold',
      description_2: 'Employee productivity increase rate when rewarded with cash and gold',
      description_3: 'Team productivity increase rate when rewarded with cash and gold',
    },
    survey_expense: {
      title_1: 'Cost Survey',
      title_2: 'for Rewards and Marketing Programs',
      reward: 'Reward',
      expenses: 'Marketing Expenses',
      b2b_company: 'B2B Company',
      b2c_company: 'B2C Company',
      salary: 'Annual Salary'
    },
    benefit_solution: {
      title: 'Advantages of HanaGold’s Business Solutions',
      description_1: 'Reducing the costs of gift investments, time, effort, and processes',
      description_2: 'Hoard and earn profits from reward and gift funds',
      description_3: 'Store gift details',
      description_4: 'Referral commission policies, account opening, gold purchases, investment',
      description_5: 'Valuable, luxurious, and refined gold gifts',
      description_6: 'Fast, convenient, online gifts',
      description_7: 'Diverse forms of gifts: Gold, VND',
    },
    another_feature: {
      title_1: 'Additional Features',
      title_2: 'Businesses Can Utilize at HanaGold',
      item_1: {
        title: 'Investment',
        description: 'Earn daily rewards with 30, 90, and 365-day terms'
      },
      item_2: {
        title: 'Daily reward',
        description: 'Earn daily rewards when storing money or gold in the main wallet'
      },
      item_3: {
        title: 'E-Voucher',
        description: 'Give gold as gifts to relatives, friends, family'
      },
      button_view_more: 'View more'
    },
    guide_use: {
      title: 'User Guide',
      description: 'See details in the “Guide” section',
      button_guide: 'Guide'
    }
  }
};
